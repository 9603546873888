import { UpdateSpeed } from "../../../.store/calculator/actions/updateSpeed";
import { UpdateRouter } from "../../../.store/calculator/actions/updateRouter";
import { UpdateTv } from "../../../.store/calculator/actions/updateTv";

const dispatcher = (dispatch) => ({
  updateSpeed: (value) => dispatch(UpdateSpeed(value)),
  updateRouter: (value) => dispatch(UpdateRouter(value)),
  updateTv: (value) => dispatch(UpdateTv(value)),
});

export default dispatcher;
