import Update from "immutability-helper";

export const SET_CALCULATOR = new String("SET_CALCULATOR");

export const SetCalculator = (data) => ({
  type: SET_CALCULATOR,
  payload: data,
});

export const MutateCalculator = (state, data) =>
  Update(state, { $merge: data });

export const UpdateCalculator = (speed) => async (dispatch) => {
  dispatch(
    SetCalculator({
      address: "",
      name: "",
      phone: "",
      speed,
      router: null,
      router_rent: false,
      tv: null,
      tv_rent: false,
    })
  );
};
