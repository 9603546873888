export default {
  address: "",
  name: "",
  phone: "",
  speed: 0,
  router: null,
  router_rent: false,
  tv: null,
  tv_rent: false,
};
